.columns-row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: $padding-1;
  @include self-sizing();
  & > .column {
    @include column-sizing($padding-1);
    flex-shrink: 0;
    flex-grow: 0;
  }
  &.no-gap {
    gap: 0;
    &>.column {
      @include column-sizing(0px);
    }
  }
  &.no-row-gap {
    row-gap: 0;
  }
  &.no-padding {
    margin-left: unset;
    margin-right: unset;
    &>.column {
      padding-left: unset;
      padding-right: unset;
    }
  }
  &.no-wrap {
    flex-wrap: nowrap;
    &>.column {
      flex-shrink: initial;
    }
  }
  &.align-center {
    align-items: center;
  }
  &.align-bottom {
    align-items: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-right {
    justify-content: flex-end;
  }
  &.size-max {
    flex-grow: 1;
  }
  &.inline-flex {
    display: inline-flex;
  }
  &>.column {
    &.shrink {
      flex-shrink: initial;
    }
    &.no-shrink {
      flex-shrink: 0;
    }
    &.max-width {
      flex-grow: 1;
    }
    &.direction-column {
      display: flex;
      flex-direction: column;
    }
    &.align-center {
      display: flex;
      align-items: center;
    }
    &.align-top {
      display: flex;
      align-items: flex-start;
    }
    &.align-bottom {
      display: flex;
      align-items: flex-end;
    }
    &.inline-flex {
      display: inline-flex;
    }
    &.justify-left {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &.justify-right {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    &.justify-center {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &.center {
      justify-content: center;
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }

  .main-content {
    flex-basis: 0;
    flex-grow: 1;
    overflow: hidden;
  }
  .max-width {
    flex-basis: 0;
    flex-grow: 1;
  }

  .size-max {
    flex-grow: 1;
  }
  .sidebar {
    width: 350px;
    position: relative;
    overflow: hidden;
  }
}

.columns-flex {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  &.align-center {
    align-items: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &>.column {
    &.center {
      text-align: center;
    }
  }
  &.rows {
    flex-direction: column;
    &>.column {
      width: 100%;
    }
  }

  &.full-width {
    & > .column {
      flex-grow: 1;
    }
  }
}

.columns-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, auto));
  min-width: 100%;
  //overflow: hidden;
  justify-content: space-between;
  &>.column {

  }
  &.align-center {
    align-items: center;
  }
  &.auto-columns {
    grid-auto-columns: 1fr;
  }
}

.img-holder {
  font-size: 0;
  display: flex;
  img {
    max-width: 100%;
  }
}

.content-block {
  &.filled {
    padding: 32px 24px;
    background: #fff;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }
  & + .content-block {
    margin-top: 28px;
  }
}

.text {
  &.center {
    text-align: center;
  }
}
