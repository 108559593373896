$field-margin: 16px;
$color-0: #5E5873;
$color-1: #5E5873;

@mixin placeholder ($color: inherit, $font-size: inherit) {
  &::placeholder {
    color: $color;
    font-size: $font-size;
    font-weight: 300;
    opacity: 1;
  }
}

.field {
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  color: #5E5873;
  label {
    display: block;
    color: #5E5873;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    margin: 0 0 5px 0;
    span {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .react-select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .select__control {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #D8D6DE;
    }
  }
  .input-block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: 0.3s ease-out all;
    min-height: 38px;
    padding: 0 15px;
    font-weight: 400;
    line-height: 1.5;
    background: #FFFFFF;
    border: 1px solid #D8D6DE;
    border-radius: 5px;
    input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="date"], input[type="datetime"], input[type="time"], input[type="password"], input[type="search"], textarea {
      border: none;
      background: none;
      border-radius: 0;
      width: 0;
      padding: 0;
      max-width: 100%;
      box-sizing: border-box;
      box-shadow: none !important;
      min-height: 36px;
      display: flex;
      font-size: inherit;
      font-weight: 500;
      flex-grow: 1;
      color: inherit;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      @include placeholder(#B9B9C3, 16px);
      &:hover {
        &:not(:disabled) {

        }
      }
      &:focus, &:active {
        &:not(:disabled){

        }
      }
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    textarea {
      height: 80px;
    }
    &.password-visible {
      .icon-eye {
        color: $color-1;
      }
    }
    &:after {
      font-family: 'font-icons';
      font-style: normal;
      font-weight: normal;
      speak: none;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 25px;
      height: 25px;
      font-size: 10px;
      line-height: 100%;
      border-radius: 1000px;
      overflow: hidden;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-right: 10px;
    }
    .show-password {
      cursor: pointer;
      font-size: 15px;
      color: #CCCCCC;
      margin-right: 10px;
    }
    &.textarea {
      align-items: flex-start;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .font-icons {
      margin-right: 12px;
      color: #a1a8aa;
      font-size: 14px;
      &.icon-eye {
        margin-right: 0;
        margin-left: 12px;
        cursor: pointer;
      }
      &.status  {
        display: none;
        margin-right: 0;
      }
    }
  }
  .message {
    display: block;
    font-family: inherit;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    margin-top: 2px;
    padding: 0;
    &.error {
      color: #ff3a3a;
    }
  }

  &.disabled {
    .input-block {
      background: #EFEFEF;
      input {
        cursor: not-allowed;
        -webkit-text-fill-color: #B9B9C3;
        opacity: 1; /* required on iOS */
        color: #B9B9C3;
        @include placeholder(#B9B9C3);
      }
    }
    .select__control {
      background: #EFEFEF;
      cursor: not-allowed;
      -webkit-text-fill-color: #B9B9C3;
      opacity: 1; /* required on iOS */
      color: #B9B9C3;
      @include placeholder(#B9B9C3);
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  &.error {
    .input-block {
      border-color: #ff3a3a;
    }
    .react-select {
      .select__control {
        border-color: #ff3a3a;
      }
    }
    .message {
      color: #ff3a3a;
    }
    .font-icons {
      &.status {
        color: #ff3a3a;
        display: inline-flex;
        &:before { content: '\e818'; }
      }
    }
  }
  &.success {
    .message {
      color: #5db553;
    }
    .font-icons {
      &.status {
        color: #5db553;
        display: inline-flex;
        &:before { content: '\e806'; }
      }
    }
  }
  &.required {
    .input-block {
      position: relative;
      &:before {
        content: '*';
        display: block;
        position: absolute;
        top: 3px;
        left: 4px;
        color: #ff3a3a;
        line-height: 100%;
      }
    }
  }
  &.focus {
    .input-block {
      border-color: $color-1;
    }
  }
  &.form-group {
    .group {
      display: flex;
      align-items: stretch;
      border-radius: 6px;
      width: 100%;

      &>* {
        &:not([class*="bt"]) {
          box-shadow: none;
        }
        border-radius: 0;
        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          flex-shrink: 0;
          border-radius: 0 6px 6px 0;
        }
      }
      .input-block {
        flex-grow: 1;
      }
    }
  }
}

.field-group {
  label {
    display: block;
    color: $color-0;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.2;
    margin: 0 0 5px 0;
    span {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .columns-grid {
    align-items: center;
    gap: 10px;
    grid-template-columns: 3fr auto auto;
  }
}

.field-row {
  & + * {
    margin-top: $field-margin;
  }
}

.select {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  //background-image: url('#{$icon-url}/chevron-down-solid.svg');
  background-position: calc(100% - 15px) center;
  background-size: 15px 17px;
  background-repeat: no-repeat;
  transition: 0.3s ease-out all;
  background-color: #fff;
  text-overflow: ellipsis;
  border: 1px solid #dddddd;
  border-radius: 4px;
  min-height: 42px;
  padding: 0 45px 0 15px;
  font-weight: 400;
  line-height: 42px;

  /* Hide arrow icon in IE browsers */
  &::-ms-expand {
    display: none;
  }
  /* Hover style */
  &:hover {
    //border-color: #888;
  }
  /* Focus style */
  &:focus {
    border-color: #aaa;
    outline: none;
  }

  /* Set options to normal weight */
  option {
    font-weight:normal;
    color: $color-0;
  }

  /* Disabled styles */
  &:disabled, &[aria-disabled=true] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  }

  &:disabled:hover, &[aria-disabled=true] {
    border-color: #aaa;
  }
}

.form-control, .form-select {
  font-size: 12px;
}

.react-datepicker-wrapper {
  .react-datepicker__close-icon {
    &:after {
      background-color: #7367f0;
    }
  }
}

.form-check {
  cursor: pointer;
  .form-check-input {
    cursor: pointer;
  }
  .message {
    display: block;
    font-family: inherit;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    margin-top: 2px;
    padding: 0;
    &.error {
      color: #ff3a3a;
    }
  }
}

.form-check-input {
  &:invalid ~ .form-check-label, &.is-invalid ~ .form-check-label {
    color: inherit;
  }
}

.form-check-label {
  &.form-label {
    font-size: 14px;
    font-weight: 400;
  }
}

.form-switch {
  margin: 0;
  padding: 0;
  .form-check-input {
    margin: 0;
    cursor: pointer;
  }
}







  .form {
    .field + .text-forgot {
      margin-top: $field-margin;
    }
    .text-block {
      font-size: 18px;
      color: $color-2;
      margin-bottom: 25px;
    }
    .text-forgot {
      text-align: center;
      color: $color-2;
      display: block;
      margin-top: 20px;
      & + * {
        margin-top: 20px;
      }
    }
    .buttons-part {
      margin-top: 20px;
    }
    .form-info {
      margin-top: 30px;
      font-size: 12px;
      & > * + * {
        margin-top: 2px;
        margin-bottom: 0;
      }
    }
  }










