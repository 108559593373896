@mixin status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  min-height: 20px;
  padding: 2px 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  border-radius: 1000px;
  &.auto-width {
    min-width: auto;
  }
  &.process,  &.in-active {
    background: $color-6;
  }
  &.paid, &.success, &.active {
    background: $color-4;
  }
  &.partially-paid, &.intermediate, &.process {
    background: $color-5;
  }
  &.overpaid {
    background: $color-4;
  }
  &.waiting {
    background: $color-10;
  }
  &.expired, &.moderate {
    background: $color-11;
  }
  &.error, &.fail {
    background: $color-2;
  }
  &.cancel {
    background: $color-8;
  }

  .font-icons {
    width: 16px;
    height: 16px;
    font-size: 16px;
    border-radius: 1000px;
    overflow: hidden;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &.success {
      color: $color-4;
    }
    &.moderate {
      background: #5E5873;
    }
    &.in-active {
      color: #FF4445;
    }
    &.cancel {
      background: #ECEBF3;
      color: #5E5873;
      font-size: 6px;
    }
    &.error {
      background: #FF4445;
      color: #fff;
      font-size: 6px;
    }
    &.process {
      background: #5E5873;
      font-size: 6px;
    }
    &.payment {
      background: #53A7E3;
      font-size: 6px;
    }
  }
}

.status {
  @include status;
}

.limit-block {
  display: flex;
  gap: 15px;
  .text {
    font-size: 10px;
    p {
      margin: 0;
      padding: 0;
      line-height: inherit;
    }
  }
  .quantity-toggler {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .bt {
      min-width: unset;
      min-height: unset;
      width: 20px;
      height: 20px;
      padding: 0;
      border-radius: 5px;
      flex-shrink: 0;
      position: relative;
      z-index: 2;
      font-size: 18px;
      font-family: sans-serif;
      font-weight: 400;
    }
    .field {
      .input-block {
        width: 67px;
        height: 20px;
        min-height: unset;
        margin-left: -6px;
        margin-right: -6px;
        position: relative;
        z-index: 1;
        input {
          text-align: center;
        }
      }
    }
  }
}

.overflow-block {
  flex: 1;
  min-width: 0;
  .overflow-item {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb {
  .link-home {
    display: flex;
  }
}

.breadcrumbs-mobile {
  margin-bottom: 25px;
  .breadcrumb-item {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 24px;
    color: $color;
    &:hover {
      color: $color;
    }
    .font-icons {
      font-size: 14px;
    }
    .link-arrow-back {
      display: flex;
      align-items: center;
      color: $color;
    }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
      width: 0;
    }
  }
}


.badge {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  padding: 1px 9px;
  border-radius: 1000px;
  color: #fff;
  &.error {
    background: $color-2;
  }
  &.success {
    background: $color-4;
  }
  &.process {
    background: $color-6;
  }
  &.cancel {
    background: $color-8;
  }
}

.ellipsis {
  display: flex;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
    width: 0;
  }
}

.alert {
  font-size: 12px;
  font-weight: 400;
  .heading-caption {
    &:not(.no-margin) {
      margin-bottom: 0.5em;
    }
  }
}

.transaction-message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  line-height: 18px;
  &.error {
    color: $color-2;
  }
  .code {
    font-weight: 700;
  }
  .text {

  }
}

.transaction-status-message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  line-height: 18px;
  &.error {
    color: $color-2;
  }
  .code {
    font-weight: 700;
  }
  .text {

  }
}

.transactions-widgets {
  .widgets-holder {
    &>.columns-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
    }
  }
  .widget-card {
    padding: 15px 28px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    .amount-block {
      display: block;
      .title {
        color: $color;
        font-size: 12px;
        line-height: 18px;
      }
      .value {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }
      &.general {
        color: $color-3;
      }
      &.withdrawal {
        color: $color-5;
      }
      &.payed {
        color: $color-4;
      }
      .amount-credit {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        color: $color-5;
      }
    }
    .icon-status {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1000px;
      width: 48px;
      height: 48px;
      font-size: 14px;
      overflow: hidden;
      &.debit {
        color: $color-4;
        background: rgba(40, 199, 111, 0.12);
      }
      &.credit {
        color: $color-5;
        background: rgba(255, 159, 67, 0.12);
      }
    }
  }
}

.line-divider {
  display: flex;
  height: 1px;
  background: #EAEAEA;
  margin: 10px 0;
  &.no-margin {
    margin: 0;
  }
  &.horizontal {
    width: 100%;
  }
  &.vertical {
    display: block;
    background: none;
    color: #EAEAEA;
    &:before {
      font-size: inherit;
      content: '|'
    }
    height: 100%;
    margin: 0;
    &.mr-1 {
      margin-left: 5px;
      margin-right: 5px;
    }
    &.v-2 {
      position: relative;
      width: 1px;
      height: inherit;
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #EAEAEA;
      }
    }
  }
}

.text {
  &.bold {
    font-weight: 600;
  }
}

.records-header {
  margin-bottom: 15px;
}

.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 998;
  visibility: hidden;
  background-color: rgba(34, 41, 47, 0.5);
  transition: all 0.3s ease;
  &.show {
    transition: all 0.3s ease;
    visibility: visible;
    opacity: 0.5;
  }
}

.body-sidebar-open {
  overflow: hidden;
}

.records-filter, .sidebar-filter {
  .select__control {
    font-size: 12px;
    color: $color-9;
    background-color: #fff;
    border-color: #d8d6de;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    min-height: 36px;
  }
  .select__menu {
    font-size: 12px;
  }
  .select__indicator {
    padding: 7px;
  }
  .filter-items {
    display: grid;
    grid-gap: 15px;
    & >.columns-row {
      row-gap: 20px;
    }
    & + * {
      margin-top: 20px;
    }
    .item {
      .noUi-target{
        margin-bottom: 15px;
      }
      .form-check {
        cursor: pointer;
        & + * {
          margin-top: 5px;
        }
      }
    }
  }
  .item-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}

.sidebar-filter {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  transform: translateX(-100%);
  width: 350px;
  max-width: 100%;
  transition: all 0.3s ease;
  visibility: hidden;
  overflow-y: auto;
  &.show {
    visibility: visible;
    transition: all 0.3s ease;
    transform: none;
  }
  .card {
    border-radius: 0;
    margin: 0;
    box-shadow: none;
  }
  .card-body {
    padding: 28px 18px;
    background: #fff;
  }
  .filter-heading {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .bt-filter-close {
    cursor: pointer;
    display: flex;
    line-height: 100%;
    .font-icons {
      font-size: 12px;
    }
  }
}

.records-filter {
  .card {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  }
  .accordion-button {
    padding: 0;
    &:after {
      width: 22px;
      height: 22px;
      background-size: 22px;
    }
  }
  .accordion-body {
    padding: 28px 0 0 0;
  }
  .card-body {
    padding: 14px 18px;
    background: #fff;
    border-radius: 12px;
  }
  .filter-heading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  &:not(.modal-slide-in) {
    .modal-dialog {
      &:not(.modal-fullscreen) {
        padding-left: calc(2rem - 0.8rem);
        padding-right: calc(2rem - 0.8rem);
      }

    }
  }
  .modal-dialog {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    &.dialog {
      max-width: 800px;
      .content-block {
        width: max-content;
      }
    }
    &.v-2 {
      .modal-header {
        background: #fff;
        color: $color;
      }
      .modal-body {
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }
  }
  .withdrawal {
    .modal-header {
      background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 100%);
    }
  }
  .deposit {
    .modal-header {
      background: linear-gradient(45.79deg, #07A624 0%, #48DA89 100%, #40C47A 100%);
    }
  }
  .modal-header {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 28px;
    padding-bottom: 28px;
    //min-height: 120px;
    color: #fff;
    background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      transform: translate(0px, 0px);
      &:hover,
      &:focus,
      &:active {
        transform: translate(2px, -2px);
      }
    }
    .modal-title {
      text-align: center;
      .heading-caption {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        &:not(.no-margin) {
          margin-bottom: 7px;
        }
        .font-icons {
          font-size: 12px;
        }
      }
    }
  }
  .accordion {
    .accordion-button {
      padding: 0;
      .text {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .accordion-body {
      padding: 15px 0 0 0;
    }
  }
  .secret-key {
    margin-top: 30px;
    word-break: break-all;
  }
  .form-buttons {
    margin-top: 35px;
  }
  .modal-body {
    padding: 35px;
  }
  .content-block {
    width: 520px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .form {
    .field-row {
      & + .text {
        margin-top: 20px;
      }
    }
    .text {
      //margin-bottom: 15px;
    }
  }

  .alert {
    margin: 25px 0;
  }

  .request-status-image {
    display: flex;
    justify-content: center;
    font-size: 95px;
    color: $color-4;
    & + * {
      margin-top: 25px;
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}

.accordion {
  .accordion-button {
    &:after {
      width: 18px;
      height: 18px;
      background-size: 20px;
      transform: rotate(-180deg);
    }
    &:not(.collapsed) {
      &:after {
        transform: rotate(0deg);
      }
    }

  }
}

.pagination {
  background: #F3F2F7;
  border-radius: 1000px;
  .page-item {
    &.disabled {
      .font-icons {
        opacity: 0.5;
      }
    }
    &.prev-item, &.next-item {
      .page-link {
        border-radius: 100px;
        display: flex;
        align-items: center;
        gap: 6px;
        background: #F3F2F7;
        .font-icons {
          color: $color-3;
        }
        &:before, &:after {
         display: none;
        }
        &:hover, &:active {
          color: #fff;
          .font-icons {
            color: inherit;
          }
        }
      }
    }
  }
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid rgba(255, 255, 255, 0.51);
    border-bottom-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.nav-menu-toggle {
  cursor: pointer;
}

.tabs {
  & > .tab-panel {
    display: flex;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: $color-12;
    margin-bottom: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    box-shadow: 0 -1px 0px 0px $color-12 inset;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & > .item {
      gap: 10px;
      display: flex;
      align-items: center;
      padding: 0 12px 10px 12px;
      cursor: pointer;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: 0;
        height: 1.5px;
        background: transparent;
        transition: 0.3s ease-out all;
      }
      & > .font-icons {
        font-size: 20px;
      }
      &.active {
        color: $color-3;
        &:before {
          left: 0;
          right: 0;
          background: $color-3;
        }
      }
    }
  }
  & > .tab-content {
    & > .item {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}