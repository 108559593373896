@include font-face (
        $font-name: 'icomoon',
        $font-family: 'font-icons',
        $font-path: '/assets/fonts/font-icons/fonts'
);

.font-icons {
  position: relative;
  display: inline-flex;
  line-height: 100%;
  &:before, &:after {
    flex-shrink: 0;
    font-family: 'font-icons';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: inherit;
    width: auto;
    line-height: 100%;
  }
}

.icon-chevron-up:before {
  content: "\e93a";
}
.icon-chevron-down:before {
  content: "\e93d";
}
.icon-clock:before {
  content: "\e937";
}
.icon-key:before {
  content: "\e938";
}
.icon-convert:before {
  content: "\e93b";
}
.icon-money-change:before {
  content: "\e93c";
}
.icon-settings:before {
  content: "\e934";
}
.icon-link-2:before {
  content: "\e935";
}
.icon-duuble-chevron-right:before {
  content: "\e936";
}
.icon-case:before {
  content: "\e939";
}
.icon-alert-circle:before {
  content: "\e933";
}
.icon-refresh:before {
  content: "\e932";
}
.icon-success:before {
  content: "\e931";
}
.icon-book:before {
  content: "\e92f";
}
.icon-minus-flat:before {
  content: "\e930";
}
.icon-curve-down-arrow:before {
  content: "\e92d";
}
.icon-curve-up-arrow:before {
  content: "\e92e";
}
.icon-cancel-thick:before {
  content: "\e92c";
}
.icon-arrow-down-left-thick:before {
  content: "\e927";
}
.icon-arrow-up-right-thick:before {
  content: "\e928";
}
.icon-time-thick:before {
  content: "\e92a";
}
.icon-check-thick:before {
  content: "\e92b";
}
.icon-alert-thick:before {
  content: "\e929";
}
.icon-arrow-left:before {
  content: "\e926";
}
.icon-burger:before {
  content: "\e923";
}
.icon-sort:before {
  content: "\e924";
}
.icon-filter:before {
  content: "\e925";
}
.icon-chevron-right:before {
  content: "\e921";
}
.icon-chevron-left:before {
  content: "\e922";
}
.icon-floppy-disk:before {
  content: "\e920";
}
.icon-doc:before {
  content: "\e91e";
}
.icon-squares:before {
  content: "\e91f";
}
.icon-upload:before {
  content: "\e91d";
}
.icon-arrow-right:before {
  content: "\e91c";
}
.icon-arrow-rounded-down-left:before {
  content: "\e91a";
}
.icon-arrow-rounded-up-right:before {
  content: "\e91b";
}
.icon-arrow-up-right:before {
  content: "\e918";
}
.icon-arrow-down-left:before {
  content: "\e919";
}
.icon-plus-2:before {
  content: "\e915";
}
.icon-edit:before {
  content: "\e916";
}
.icon-lock:before {
  content: "\e917";
}
.icon-diskette:before {
  content: "\e914";
}
.icon-plus:before {
  content: "\e913";
}
.icon-trash:before {
  content: "\e912";
}
.icon-close-no-bg:before {
  content: "\e911";
}
.icon-process:before {
  content: "\e910";
}
.icon-alert:before {
  content: "\e90f";
}
.icon-pencil:before {
  content: "\e90e";
}
.icon-copy:before {
  content: "\e90c";
}
.icon-time:before {
  content: "\e909";
}
.icon-close:before {
  content: "\e90d";
}
.icon-info:before {
  content: "\e903";
}
.icon-minus:before {
  content: "\e907";
}
.icon-credit-card:before {
  content: "\e905";
}
.icon-download:before {
  content: "\e906";
}
.icon-link:before {
  content: "\e908";
}
.icon-notification:before {
  content: "\e90a";
}
.icon-smartphone:before {
  content: "\e90b";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-magnifying-glass:before {
  content: "\e902";
}
.icon-circle-plus:before {
  content: "\e901";
}
.icon-circle-check:before {
  content: "\e900";
}





/*

.font-icon(@icon) {
  content: @icon;
  font-family: @icon-font-family;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: inherit;
  width: auto;
  line-height: 1em;
}
 */