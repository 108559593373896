h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: $color;
}
.heading-caption {
  font-size: inherit;
  font-weight: 500;
  color: inherit;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin-bottom: 0.8em;
  position: relative;
  line-height: 1.2;
  margin-top: 0;
  letter-spacing: initial;
  a {
    color: inherit;
  }
  &.center {
    text-align: center;
  }
  &.left {

  }
  &.s-2 {
    font-size: 28px;
  }
  &.s-2-2 {
    font-size: 24px;
  }
  &.s-3 {
    font-size: 21px;
  }
  &.s-4 {
    font-size: 18px;
  }
  &.s-5 {
    font-size: 16px;
  }
  &.s-6 {
    font-size: 14px;
  }
  &.s-7 {
    font-size: 12px;
  }

  &.full-width {
    min-width: 290px;
    width: auto;
  }
  &.regular {
    font-weight: 400;
  }
  &.bold {
    font-weight: 600;
  }
  &.bold-extra {
    font-weight: 700;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.no-margin {
    margin: 0;
  }
  &.capitalize {
    &:first-letter {
      text-transform: capitalize
    }
  }
  &.brake-all {
    word-break: break-all;
  }
  &.primary-color {
    color: $color-3;
  }
  &.gray-color {
    color: $color-9
  }
}

.heading-block {
  margin-bottom: 30px;
  &.no-margin {
    margin: 0;
  }
  &.justify-center {
    display: grid;
    justify-items: center;
    grid-gap: 30px;
  }
  .heading-caption {
    margin: 0;
  }
  .sub-caption {

  }
}