.content-body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
}
.form-header {
  & + * {
    margin-top: 30px;
  }
}
.form-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .form {
    padding: 10px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.form-footer {

}
.payment-form-block {
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  color: #000;
  display: flex;
  width: 675px;
  min-height: 564px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //gap: 28px;
  border-radius: 20px;
  .holder {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
.logo {
  margin-right: 10px;
  display: flex;
  width: 29px;
  height: 29px;
}
.order-info {
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin-top: 20px;
}

.common-methods {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 10px;
  align-content: center;
  &>.item {
    color: $color;
    display: flex;
    padding: 10px 10px 10px 20px;
    align-items: center;
    gap: 14px;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
    background: #fff;
    border: 1px solid #EBE9F1;
    min-height: 168px;
    cursor: pointer;
    transition: 0.3s ease-out all;
    font-size: 14px;
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0.07) 100%), #FFF;
    }
    &:active, &.active {
      border: 1px solid #000;
      background: #FFF;
    }
    .icon {
      width: 40px;
      height: 40px;
    }
  }
}

.payment-methods {
  min-height: 100%;
  flex-grow: 999;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 10px;
  margin-top: 20px;
  & + .buttons-block {
    margin-top: 20px;
  }
  &>.item {
    color: $color;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
    background: #fff;
    border: 1px solid #EBE9F1;
    cursor: pointer;
    transition: 0.3s ease-out all;
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0.07) 100%), #FFF;
    }
    &:active, &.active {
      border: 1px solid #000;
      background: #FFF;
    }
    .sub-caption {
      text-align: center;
      font-size: 12px;
      opacity: 0.8;
      margin-top: 3px;
    }
    .icon {
      width: 40px;
      height: 40px;
    }
  }
}

.scroll-holder {
  flex-basis: 0;
  flex-grow: 999;
  overflow-y: auto;
  margin:0 -20px;
  & + .buttons-block {
    margin-top: 20px;
  }
}

.payment-methods-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 5px;
  padding: 20px;
  & + .buttons-block {
    margin-top: 20px;
  }
  &>.item {
    color: $color;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
    background: #fff;
    border: 1px solid #EBE9F1;
    cursor: pointer;
    transition: 0.3s ease-out all;
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0.07) 100%), #FFF;
    }
    &:active, &.active {
      border: 1px solid #000;
      background: #FFF;
    }
    .sub-caption {
      font-size: 12px;
      opacity: 0.8;
    }
    .font-icons {
      font-size: 12px;
      color: #B9B9C3;
    }
    .icon {
      width: 32px;
      height: 32px;
    }
  }
}
.buttons-block {
  margin-top: 30px;
  align-items: end;
  flex-grow: 1;
}
.payment-methods-holder {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .icon {
    width: 32px;
    height: 32px;
  }
  .methods-header {
    box-shadow: 0 10px 10px 5px rgb(255, 255, 255);
    position: relative;
    z-index: 1;
  }
}

.preloader-block {
  display: flex;
  flex-grow: 1;
  .shadow-holder {
    width: 675px;
    min-height: 410px;
    margin-top: 0 !important;
  }
}

.alert {
  margin: 20px 0 0 0;
}

.icon-tooltip {
  margin-left: 5px;
  cursor: pointer;
}

.status-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 999;
  &:not(.error) {
    padding: 20px;
  }
  .icon-status {
    & + * {
      margin-top: 20px;
    }
  }
  .heading-caption {
    &.no-margin + * {
      margin-top: 8px;
    }
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    color: $color;
    .payment-amount {
      color: #000;
    }
  }
}
.footer {
  margin-top: 30px;
  text-align: center;
  font-size: 12px;
  .text-block + * {
    margin-top: 4px;
  }
}

.countdown-timer {
  font-size: 14px;
  font-weight: 500;
  color: $color-9;
}

.card-info {
  margin-top: 40px;
}

.card-provider-icon {
  height: 16px;
  img {
    height: 100%;
  }
}

.payment-info {
  margin-top: 40px;
  border-radius: 6px;
  color: $color;
  & > * + * {
    margin-top: 14px;
  }
  .account-icon {
    width: 32px;
    max-height: 32px;
    margin-right: 8px;
  }
  .profile-icon {
    padding: 3px;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  .icon-copy {
    width: 20px;
  }
  .heading-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: max-content;
  }
}

.text-block {
  &.info {
    color: $color;
    display: flex;
    padding: 16px 8px;
    align-items: flex-start;
    gap: 59px;
    align-self: stretch;
    border-radius: 6px;
    margin-top: 20px;
    font-size: 14px;
    background: rgba(255, 159, 67, 0.12);
  }
}