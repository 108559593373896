@media (max-width: $lg) {

}

@media (max-width: $md) {

}

@media (max-width: $sm) {

}

@media (max-width: $xs) {
  .content-body {
    padding: 0;
  }
  .payment-form-block {
    border-radius: 0;
    padding: 0;
    min-height: 100vh;
    .holder {
      padding: 10px;
      min-height: inherit;
      display: flex;
      flex-direction: column;
    }
    .scroll-holder {
      margin: 0 -10px;
    }
  }
  .common-methods {
    gap: 5px;
    align-content: initial;
    & > .item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }
  }
  .payment-methods-holder {
    margin-top: 20px;
    flex-grow: 999;
    .icon {
      width: 28px;
      height: 28px;
    }
    .heading-caption.s-4 {
      font-size: 14px;
    }
    .countdown-timer {
      font-size: 14px;
    }
    .card-info {
      margin-top: 20px;
    }
    .methods-header {
      box-shadow: 0px 5px 5px 5px rgb(255, 255, 255);
    }
  }
  .payment-methods {
    margin-top: 15px;
    gap: 5px;
    & > .item {
      padding: 8px;
      min-height: 190px;
      &:hover {
        background: #fff;
      }
    }
  }
  .payment-methods-list {
    padding: 15px 10px;
    gap: 5px;
    & > .item {
      padding: 8px;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);
      &:hover {
        background: #fff;
      }
    }
  }
  .payment-info {
    .account-icon {
      width: 24px;
      max-height: 24px;
    }
    .profile-icon {
      width: 24px;
      height: 24px;
    }
    .heading-caption.s-5 {
      font-size: 14px;
    }
  }
  .text-block.info {
    font-size: 12px;
  }
  .form-header + * {
    margin-top: 20px;
  }
  .form-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .preloader-block {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .shadow-holder {
      flex-grow: 1;
      width: 100vw;
    }
  }
}

@media (min-width: $md) {
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: $sm + 1) and (max-width: $md) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: $xs + 1) and (max-width: $sm) {
  .hide-sm {
    display: none !important;
  }
}

@media (max-width: $xs) {
  .hide-xs {
    display: none !important;
  }
}